<template>
  <div class="page">
    <div class="heading">
      <v-toolbar dense :flat="flatapp">
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          style="padding: 14px 20px"
          class="capitalize"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" href="https://www.umohe.com" target="_blank">
              <v-icon size="16" color="primary">fa-home</v-icon>
            </v-btn>
          </template>
          <span>Go home</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body dash">
      <v-row>
        <v-col cols="12" lg="6">
          <v-card flat dark color="rgb(34, 142, 255)" min-height="240">
            <v-overlay absolute :value="overUser"></v-overlay>
            <v-toolbar flat color="rgb(34, 142, 255)" dark>
              <v-toolbar-title style="color: white">
                Welcome back, Dr. Mansah Musah Qhalisfa
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon color="">
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text style="padding-bottom: 16px">
              <v-row>
                <v-col class="split">
                  <v-card flat color="rgb(34, 142, 255)">
                    <v-card-title>
                      <div class="statitle">appointments</div>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <div class="statname">today</div>
                          <div class="statext">10</div>
                        </v-col>
                        <v-col>
                          <div class="statname">tommorrow</div>
                          <div class="statext">5</div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card flat color="rgb(34, 142, 255)">
                    <v-card-title>
                      <div class="statitle">requests</div>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <div
                            class="statname"
                            style="padding-bottom: 20px"
                          ></div>
                          <div class="statext">5</div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3">
          <v-card flat height="240">
            <v-overlay absolute :value="overAppoint"></v-overlay>
            <v-toolbar flat>
              <v-toolbar-title>on Appointments</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon color="primary">
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text style="padding: 0px 16px">
              <v-overlay absolute :value="overTreat"></v-overlay>
              <v-list dense style="padding: 0px">
                <v-list-item-group color="info">
                  <v-list-item v-for="item in items" :key="item.id">
                    <v-list-item-avatar
                      size="35"
                      color="red"
                    ></v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3">
          <v-card flat>
            <v-overlay absolute :value="overTreat"></v-overlay>
            <v-sheet
              color="#f0f8fe"
              elevation="6"
              style="padding: 10px"
              rounded
              height="240"
            >
              <v-toolbar flat color="#f0f8fe">
                <v-toolbar-title>
                  <v-btn fab large depressed>
                    <v-icon color="indigo" size="64"> mdi-heart-pulse</v-icon>
                  </v-btn>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <div class="chartitle">
                  Treatments
                  <span class="sub">this week</span>
                </div>
              </v-toolbar>
              <v-sparkline
                height="95"
                :value="value"
                :labels="labels"
                label-size="14"
                smooth="10"
                line-width="10"
                auto-draw-easing="ease"
                type="trend"
                padding="16"
                style="padding: 7px 0px; color: rgb(185, 96, 185)"
              ></v-sparkline>
            </v-sheet>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-card flat>
            <v-overlay absolute :value="overEarn"></v-overlay>
            <v-toolbar flat>
              <v-toolbar-title class="earn_title">
                Earning Reports
                <div class="sub">Weekly Earnings</div>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="5" style="padding: 35px 0px 0px 16px">
                  <div style="font-size: 2.2em; font-family: 'Merriweather'">
                    2,150.00
                  </div>
                  <div style="padding: 8px 0px 0px 0px; font-size: 0.85em">
                    You informed of this week compared to last week
                  </div>
                </v-col>
                <v-col cols="12" lg="7">
                  <v-sparkline
                    fill
                    :value="value"
                    :labels="labels"
                    label-size="14"
                    smooth="10"
                    line-width="10"
                    auto-draw-easing="ease"
                    type="bar"
                    style="padding: 7px 0px; color: rgb(185, 96, 185)"
                  ></v-sparkline>
                </v-col>
              </v-row>
              <v-card flat outlined style="margin: 16px 0px">
                <v-card-text style="padding: 16px">
                  <v-row>
                    <v-col cols="5">
                      <div>Patients</div>
                      <div class="subset">total patients</div>
                      <v-progress-linear rounded v-model="knowledge" height="5">
                      </v-progress-linear>
                    </v-col>
                    <v-col cols="7">
                      <div>Value</div>
                      <div class="subset">amount value per session</div>
                      <v-progress-linear
                        color="green"
                        rounded
                        v-model="knowledge"
                        height="5"
                      >
                      </v-progress-linear
                    ></v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6">
          <v-card flat>
            <v-overlay absolute :value="overClient"></v-overlay>
            <v-toolbar flat>
              <v-toolbar-title class="earn_title">
                Client Reports
                <div class="sub">Client Frequency</div>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="5" style="padding: 35px 0px 0px 16px">
                  <div style="font-size: 2.2em; font-family: 'Merriweather'">
                    4
                  </div>
                  <div style="padding: 8px 0px 0px 0px; font-size: 0.85em">
                    Average Frequency of Usage per patient
                  </div>
                </v-col>
                <v-col cols="12" lg="7">
                  <v-sparkline
                    fill
                    :value="value"
                    :labels="labels"
                    label-size="14"
                    smooth="10"
                    line-width="10"
                    auto-draw-easing="ease"
                    type="bar"
                    style="padding: 7px 0px; color: rgb(185, 96, 185)"
                  ></v-sparkline>
                </v-col>
              </v-row>
              <v-card flat outlined style="margin: 16px 0px">
                <v-card-text style="padding: 16px">
                  <v-row>
                    <v-col cols="5">
                      <div>Patients</div>
                      <div class="subset">total patients</div>
                      <v-progress-linear rounded v-model="knowledge" height="5">
                      </v-progress-linear>
                    </v-col>
                    <v-col cols="7">
                      <div>Value</div>
                      <div class="subset">amount value per session</div>
                      <v-progress-linear
                        color="green"
                        rounded
                        v-model="knowledge"
                        height="5"
                      >
                      </v-progress-linear
                    ></v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import WinScroll from "../../mixins/windowscroll";
export default {
  components: {},
  mixins: [WinScroll("position")],
  data() {
    return {
      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: { name: "medics.dash" },
        },
      ],
      knowledge: 33,
      overUser: false,
      overAppoint: false,
      overTreat: false,
      overEarn: false,
      overClient: false,
      items: [
        { id: 1, text: "Kwesi Lennox" },
        { id: 2, text: "Esi Benneh" },
        { id: 3, text: "Awo Mannaseh" },
      ],
      labels: ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"],
      value: [200, 675, 410, 390, 310, 460, 250],
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {},
  methods: {
    menulink() {
      this.$emit("sidebar");
    },
  },
};
</script>
